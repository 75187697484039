import links from 'links'
import config from 'config'
import cx from 'classnames'
import { useForm } from 'formular'
import { useMutation } from 'hooks'
import { usePathname } from 'router'
import { useUser } from 'models/user'
import { closeAllModals } from 'modal'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useQueryData } from 'hooks/query/context'
import { required, email } from 'helpers/validators'
import { useCityFastOptions } from 'models/settings'

import { Text } from 'components/dataDisplay'
import { Link, Input, Textarea, Uploader, Button } from 'components/inputs'
import { openSubmitFailedModal, openSubmitSucceededModal } from 'compositions/modals'

import s from './SupportForm.scss'


type SupportFormProps = {
  className?: string
  appealCategoryId?: number
}

type SupportFormFields = {
  nameField: string
  emailField: string
  cityField: string
  messageField: string
  filesField: string[]
}

const minSymbols = (value: string) => {
  if (value.length < 20) {
    return 'Минимальное количество символов - 20'
  }
}

const SupportForm: React.FC<SupportFormProps> = (props) => {
  const { className, appealCategoryId } = props

  const { user } = useUser()
  const { id: userId, adminAccess, firstName, lastName, middleName, email: userEmail } = user || {}
  const { host } = useQueryData()
  const pathname = usePathname()

  const { cityFastOptions, isCityFastOptionsFetching } = useCityFastOptions(!adminAccess)
  const { techsuppComments } = cityFastOptions || {}

  const fio = useMemo(() => {
    return [ lastName, firstName, middleName ].filter(Boolean).join(' ')
  }, [ firstName, lastName, middleName ])

  const form = useForm<SupportFormFields>({
    fields: {
      nameField: {
        value: null,
        validate: [ required ],
      },
      emailField: {
        value: null,
        validate: [ required, email ],
      },
      cityField: {
        value: config.cityName,
      },
      messageField: {
        value: null,
        validate: [ required, minSymbols ],
      },
      filesField: {
        value: [],
      },
    },
  })

  useEffect(() => {
    if (fio) {
      form.fields.nameField.set(fio)
    }
    if (userEmail) {
      form.fields.emailField.set(userEmail)
    }
  }, [ fio, userEmail, form ])

  const { nameField, emailField, cityField, messageField, filesField } = form.fields

  const [ submitFeedback, { isSubmitting: isFeedbackSubmitting } ] = useMutation<{ error: string, ok?: number }>({
    url: '/ajax.php?action=techsupport',
    method: 'POST',
  })

  const handleSubmit = useCallback(async (pathname, host, userId) => {
    const { values, errors } = await form.submit()

    if (errors) return

    const { cityField, emailField, filesField, messageField, nameField } = values

    const body = {
      'attachments': filesField,
      'email': emailField,
      'name': nameField,
      // 'taskId': 0,
      'techdata': `Отправлено со страницы ${host}${pathname} пользователем с id: ${userId}`,
      'text': messageField,
    }

    submitFeedback(body)
      .then(({ data, cacheStorage, errors }) => {
        const { error, ok } = data || {}
        console.log({ errors })
        if (!ok) {
          openSubmitFailedModal({
            title: 'Сообщение не отправлено',
            subTitle: `Ошибка: ${error || errors}`,
            text: 'К сожалению, сообщение не было отправлено, попробуйте повторить позднее.',
          })
        }
        else {
          form.unsetValues()

          openSubmitSucceededModal({
            title: 'Сообщение отправлено',
            text: 'Спасибо, что сообщили об ошибке или предложили идею развития портала — мы ценим обратную связь.',
            redirectLink: links.home,
            callback: () => closeAllModals(),
          })
        }
      })
  }, [ form, submitFeedback ])

  if (isCityFastOptionsFetching) {
    return (<></>) // TODO add skeleton
  }

  return (
    <div className={cx(className, 'flex flex-col justify-center')}>
      <Text
        className="mt-40px"
        message="Поддержка пользователей"
        size="h32"
      />
      <div className="mt-12px">
        <Text
          className="inline opacity-32"
          message="Обращаем внимание, что письмо о проблеме, связанной с обслуживанием города, можно отправить через "
          size="t16-24"
        />
        <Link
          title="форму обращений"
          to={links.appeals.add + `${appealCategoryId ? `?categoryId=${appealCategoryId}` : ''}`}
          color="alien"
          onClick={() => closeAllModals()}
        />
      </div>
      {
        adminAccess && (
          <div
            className={cx(s.techsuppComments, 'mt-12px')}
            dangerouslySetInnerHTML={{ __html: techsuppComments }}
          />
        )
      }
      <Input
        className="mt-32px"
        label="Имя"
        field={nameField}
      />
      <div className="flex mt-32px">
        <Input
          className="flex-1"
          label="Email"
          field={emailField}
        />
        <Input
          className="flex-1 ml-24px"
          label="Город"
          field={cityField}
        />
      </div>
      <Textarea
        className="mt-24px"
        label="Сообщение"
        field={messageField}
        rows={6}
      />
      <Uploader
        className="mt-24px"
        filesField={filesField}
        acceptTypes={[ 'image', 'docs', 'video' ]}
        title="Выбрать файлы"
        message={`или перетяните сюда до 3 файлов в форматах .jpg, .png, .mp4,
          ${adminAccess ? '.doc, .docx, ' : ''}объем файла не более 25 МБайт
          ${!adminAccess ? ' , для загрузки .doc, .docx - необходима авторизация' : ''}`}
        orderId={-1000}
        maxFiles={3}
        multipleFiles
        withFilePreview
      />
      <Button
        className="mt-32px"
        title="Отправить"
        onClick={() => handleSubmit(pathname, host, userId)}
        fullWidth
        style="primary"
        size={40}
      />
    </div>
  )
}

export default SupportForm