import { useQuery } from 'hooks'
import { useMemo } from 'react'


const useCityFastOptions = (skip?: boolean) => {
  const options = useMemo(() => ({
    url: '/ajax.php?action=getCityFastOptions',
    skip,
  }), [ skip ])

  const { data, isFetching, errors } = useQuery<Settings.CityFastOptions>(options)

  return {
    cityFastOptions: data || {} as Settings.CityFastOptions,
    isCityFastOptionsFetching: isFetching,
    cityFastOptionsErrors: errors,
  }
}

export default useCityFastOptions