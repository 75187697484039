import React from 'react'

import PlainNotification from 'compositions/notifications/PlainNotification/PlainNotification'

import type { NotificationProps } from 'components/feedback'


type ErrorNotificationProps = NotificationProps & {
  message: string
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ message, closeNotification }) => (
  <PlainNotification
    title="Ошибка"
    text={message}
    closeNotification={closeNotification}
  />
)


export default ErrorNotification
